import * as React from "react";
import VisibilitySensor from 'react-visibility-sensor';

interface IUseScrolledIntoView {
    partialVisibility?: boolean
};

function useScrolledIntoView({partialVisibility = false}:IUseScrolledIntoView = {}):
    [React.FC<{}>, boolean, React.Dispatch<React.SetStateAction<boolean>>]
{
    const [visible, setVisible] = React.useState<boolean>(false);
    const onScrolledIntoView = (isVisible: boolean): void => {
        if (!isVisible) {
            return;
        }

        setVisible(true);
    }

    const Sensor: React.FC<{}> = ({ children }) =>
        <VisibilitySensor
            partialVisibility={partialVisibility}
            onChange={onScrolledIntoView}
        >
            {children}
        </VisibilitySensor>

    return [Sensor, visible, setVisible];
};

export default useScrolledIntoView;
